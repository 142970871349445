import Button from "@material-ui/core/Button";
import * as React from "react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDataProvider, useRefresh, useTranslate, LoadingIndicator} from "react-admin";
import { DropzoneAreaBase } from 'material-ui-dropzone';
import {createStyles ,makeStyles} from "@material-ui/core/styles";
import FileUploader from "./FilerUploader";

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

const TransitionButton = ({transition, record, showComment, onSubmit}) => {
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const refresh = useRefresh();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {

        const obj = {
            claim: record.id,
            transition: transition,
            comment: comment
        }

        setIsSubmitting(true);
        onSubmit();

        dataProvider.create('damage-reports/transition-requests', {data: obj})
            .then(data => {
                refresh();
            })
            .finally(() => {
                setIsSubmitting(false);
                setOpen(false);
            })
    }


    return (
        <div>
            <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                {translate(`resources.damage-reports.action.${transition}`)}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate(`resources.damage-reports.action.${transition}`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate(`resources.damage-reports.actionComments.${transition}`)}
                    </DialogContentText>


                    <FileUploader />

                    {
                        showComment &&
                        <TextField
                            margin="dense"
                            id="name"
                            label={translate('resources.damage-reports.comment')}
                            fullWidth
                            multiline
                            onBlur={event => setComment(event.target.value)}
                        />
                    }
                </DialogContent>
                {
                    isSubmitting ? <LoadingIndicator /> :
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {translate('resources.damage-reports.buttons.cancel')}
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            {translate('resources.damage-reports.buttons.submit')}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div>
    )

}

export default TransitionButton;