// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'application |||| application',
        title: 'Enregistrer un nouveau sinistre',
        success: 'Votre rapport de sinistre a été envoyé avec succès',
        fields: {
            numberId : 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Devise',
            createdAt : 'Date de sinistre',
            dealer : 'Société',
            contactFirstName: 'Prénom',
            contactLastName: 'Nom de famille',
            street: 'Rue',
            zip: 'Code postal',
            city: 'Ville',
            country: 'Pays',
            claimDate : 'Date de sinistre',
            mileage: 'Kilométrage',
            labourCost: 'Prix Travail',
            partsCost: 'Prix Pièces',
            totalCost: 'Prix Totaux',
            damageDescription: 'Description du sistre',
            diagnosis: 'Cause/ Diagnose',
            repairDescription: 'Reparation à effectuer',
            costCalculationFile: 'Veuillez télécharger le calcul correspondant',
            vehicleRegistrationFile: 'Veuillez télécharger les documents d\'inscription',
            serviceConfirmationFile: 'Veuillez télécharger le document de service',
            tcsInspectionFile: 'Veuillez télécharger le TCS Rapport de test du véhicule',
            status: 'Statut ',
            vatRate: 'VAT %',
        },
        buttons : {
            submit : 'Envoyer',
        },
        blocks: {
            reporter: 'Contact concessionnaire',
            contact_person: 'Contact',
            address: 'Adresse',
            vehicle: 'Véhicule',
            damage_report: 'Détail des sinistres',
        },
        status: {
            pending: 'En attente',
            accepted: 'Accepté',
            rejected: 'Rejeté',
            refused: 'Refusé',
            paid: 'Payé'
        }
    }
}