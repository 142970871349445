// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'Damage report |||| Damage reports',
        title: 'Register new claim',
        success: 'Your claims report has been successfully sent',
        fields: {
            numberId : 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Currency',
            createdAt : 'Created at ',
            dealer : 'Company',
            contactFirstName: 'First name',
            contactLastName: 'Second name',
            street: 'Street',
            zip: 'ZIP',
            city: 'City',
            country: 'Country',
            claimDate: 'Claim date',
            mileage: 'Mileage km',
            labourCost: 'Labour cost',
            partsCost: 'Parts cost',
            totalCost: 'Total cost',
            damageDescription: 'Damage description',
            diagnosis: 'Cause / diagnosis',
            repairDescription: 'Repair description',
            costCalculationFile: 'Please upload the corresponding calculation',
            vehicleRegistrationFile: 'Please upload the registration papers',
            serviceConfirmationFile: 'Please upload the service document',
            tcsInspectionFile: 'Please upload the TCS vehicle check',
            brand: 'Brand',
            model: 'Model',
            product: 'Warranty type',
            status: 'Status',
            currentWorkflowState: 'Status',
            internalComment: 'Internal comment',
            vatRate: 'VAT %',
        },
        buttons : {
            submit : 'Submit',
            cancel: 'Cancel',
        },
        blocks: {
            reporter: 'Dealer contact',
            contact_person: 'Contact Person',
            address: 'Address',
            vehicle: 'Vehicle',
            damage_report: 'Claims details',
        },
        status: {
            new: 'Awaiting for approval',
            rejected: 'Rejected',
            awaiting_second_approval: 'Awaiting second approval',
            awaiting_dealer_rectification: 'Awaiting dealer rectification',
            accepted: 'Accepted, waiting for invoice',
            awaiting_invoice_approval: 'Awaiting for invoice approval',
            invoice_accepted: 'Invoice accepted',
            invoice_refused: 'Awaiting updated invoice',
            paid: 'Paid'
        },
        action: {
            partly_accept: 'Partly accept',
            accept: 'Accept',
            refuse: 'Refuse',
            reject: 'Reject',
            update_claim_data: 'Claim data updated',
            submit_invoice: 'Submit invoice',
            accept_invoice: 'Accept invoice',
            refuse_invoice: 'Refuse invoice',
            payment_done: 'Payment done'
        },
        actionComments: {
            partly_accept: 'According to your limits you can just partly accept this claim. After your submission claim will be sent to the second claim manager.',
            accept: 'Once you click submit, request for an invoice will be sent to the dealer',
            refuse: 'Please provide refusal information to the dealer',
            reject: ' Please provide reject information to the dealer',
            update_claim_data: 'Claim data updated',
            submit_invoice: 'Submit invoice',
            accept_invoice: 'Once you click submit, dealer will be notified to wait for the payment',
            refuse_invoice: 'Please provide refusal information to the dealer',
            payment_done: ' Once you click on submit button, dealer will be notified that payment is done'
        },
        comment: 'Comment'
    }
}