// eslint-disable-next-line import/no-anonymous-default-export
export default {
  company: 'insercle',
  defaultTitle: 'Claims Platform',
  primary: '#6e756b',
  secondary: '#474b55',
  favicon: require('./favicon.png'),
  manifest: '/theme/dgd/manifest.json',
  logo: '/theme/dgd/logo256.png',

  APP_DEFAULT_LOCALE: 'ru',

}