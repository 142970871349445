import * as React from "react";
import {useEffect, useState} from "react";
import {Create, DateInput, email, FileInput, FormDataConsumer, FunctionField, LoadingIndicator, NumberInput, ReferenceInput, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useDataProvider, useLocale, useLogout, useNotify, useTranslate} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {getTokenPayload} from "../../utils/JWTUtils";
import {getAccessToken} from "../../utils/AuthUtils";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CustomFileField from "../../fields/CustomFileField";

const DamageReportCreateToolbar = (props) => {
    const translate = useTranslate()

    return (
        <Toolbar {...props} >
            <SaveButton
                label={translate('resources.damage-reports.buttons.submit')}
                icon={<SendIcon/>}
            />
        </Toolbar>
    );
}

const useStyles = makeStyles(theme => ({
    first: {display: 'inline-block', minWidth: 256},
    second: {display: 'inline-block', marginLeft: 32, minWidth: 256},
    inLine: {display: 'inline-block'},
    double: {minWidth: 544},
    hidden: {display: "none"},
    logo: {
        maxWidth: 80,
        margin: 20
    },
}));

export const DamageReportCreate = (props) => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const locale = useLocale();
    const logout = useLogout();
    const [policy, setPolicy] = useState({});
    const [loadingPolicy, setLoadingPolicy] = useState(true);

    const tokenData = getTokenPayload(getAccessToken())
    const {code = '', vin = ''} = {...tokenData};

    useEffect(() => {
        dataProvider.getList(
            'policies',
            {
                pagination: {page: 1, perPage: 1},
                sort: {field: 'id', order: 'ASC'},
                filter: {vin: vin, secretHash: code}
            })
            .then(({data}) => {
                if (0 === data.length) {
                    notify('Policy not found', 'error');
                }
                setLoadingPolicy(false);
                setPolicy(data[0]);
            })
    }, [code, dataProvider, notify, vin]);

    const onSuccess = ({data}) => {
        notify(translate('resources.damage-reports.success'), 'info', undefined);
        setTimeout(() => {
            logout()
        }, 2000)
    }

    // const validationRules = [
    //     required()
    // ];
    const validationRules = [];

    return (
        <Create title={translate('resources.damage-reports.title')} {...props} onSuccess={onSuccess}>
            <SimpleForm
                redirect="edit"
                toolbar={<DamageReportCreateToolbar/>}
                initialValues={{locale}}
            >
                {
                    loadingPolicy ?
                        <div/> :
                        <FunctionField
                            addLabel={false}
                            render={record => {
                                if (policy.productName && policy.productName.indexOf('TCS') > -1) {
                                    return <img src='/TCS-Logo-oben-CMYK-1601.png' className={classes.logo} alt=''/>
                                }

                                return <div/>
                            }}
                        />
                }
                <Typography/>

                <TextInput label={translate("resources.policies.fields.vin")} source="vin" initialValue={vin} formClassName={classes.first} disabled/>
                <TextInput source="code" initialValue={code} formClassName={classes.second} disabled/>

                <Typography/>

                {
                    loadingPolicy ?
                        <LoadingIndicator formClassName={classes.first}/> :
                        <FunctionField
                            label={translate("resources.policies.fields.makeModelText")}
                            render={record => `${policy.makeModelText}`}
                            formClassName={classes.first}
                        />
                }
                {
                    loadingPolicy ?
                        <LoadingIndicator formClassName={classes.second}/> :
                        <FunctionField
                            label={translate("resources.policies.fields.licensePlate")}
                            render={record => `${policy.licensePlate}`}
                            formClassName={classes.second}
                        />
                }

                <Typography/>

                {
                    loadingPolicy ?
                        <LoadingIndicator formClassName={classes.first}/> :
                        <FunctionField
                            label={translate("resources.policies.fields.productName")}
                            render={record => `${policy.productName}`}
                            formClassName={classes.first}
                        />
                }
                {
                    loadingPolicy ?
                        <LoadingIndicator formClassName={classes.second}/> :
                        <FunctionField
                            label={translate("resources.policies.fields.productActivationDate")}
                            render={record => (new Date(policy.productActivationDate)).toLocaleDateString()}
                            formClassName={classes.second}
                        />
                }

                {
                    loadingPolicy ?
                        <LoadingIndicator formClassName={classes.second}/> :
                        <FunctionField
                            label={translate("resources.policies.fields.productWarrantyEnd")}
                            render={record => (new Date(policy.productWarrantyEnd)).toLocaleDateString()}
                            formClassName={classes.second}
                        />
                }

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.reporter')}</Typography>
                <TextInput source="dealer" validate={validationRules} className={classes.double}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.contact_person')}</Typography>
                <TextInput source="contactFirstName" validate={validationRules} formClassName={classes.first}/>
                <TextInput source="contactLastName" validate={validationRules} formClassName={classes.second}/>
                <TextInput label="Email" source="contactEmail" validate={[required(), email()]}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.address')}</Typography>
                <TextInput source="street" validate={validationRules} formClassName={classes.first}/>
                <TextInput source="zip" validate={validationRules} formClassName={classes.second}/>
                <Typography/>
                <TextInput source="city" validate={validationRules} formClassName={classes.first}/>
                <ReferenceInput source="country" reference="countries" validate={validationRules} formClassName={classes.second}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.vehicle')}</Typography>
                <DateInput source="claimDate" validate={validationRules} formClassName={classes.first}/>
                <NumberInput source="mileage" validate={validationRules} formClassName={classes.second}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.damage_report')}</Typography>
                <ReferenceInput source="currency" reference="currencies" validate={validationRules} formClassName={classes.first} sort={{field: "name"}}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <FormDataConsumer source="vatRate" formClassName={classes.second}>
                    {({formData, ...rest}) => {
                        if (formData.country) {
                            return (
                                <ReferenceInput source="vatRate" reference="vat-rates" validate={validationRules} formClassName={classes.second} filter={{country: formData.country}} sort={{field: "rate", order: 'ASC'}} {...rest}>
                                    <SelectInput optionText={(record) => `${record.rate} %`}/>
                                </ReferenceInput>
                            );
                        }
                        else {
                            return (
                                <ReferenceInput source="vatRate" reference="vat-rates" validate={validationRules} formClassName={classes.second} filter={{country: 0}} sort={{field: "rate", order: 'ASC'}} {...rest}>
                                    <SelectInput optionText={(record) => `${record.rate} %`}/>
                                </ReferenceInput>
                            )
                        }
                    }}
                </FormDataConsumer>

                <Typography/>
                <NumberInput source="labourCost" helperText="net" validate={validationRules} formClassName={classes.first}/>
                <NumberInput source="partsCost" helperText="net" validate={validationRules} formClassName={classes.second}/>
                <FormDataConsumer formClassName={classes.second}>
                    {({formData, ...rest}) => {
                        return <NumberInput InputProps={{value: (formData.labourCost ?? 0) + (formData.partsCost ?? 0)}} source="totalCost" disabled formClassName={classes.second} {...rest} />;
                    }}
                </FormDataConsumer>

                <Typography/>
                <TextInput source="damageDescription" multiline={true} fullWidth />
                <TextInput source="diagnosis" multiline={true} fullWidth />
                <TextInput source="repairDescription" multiline={true} fullWidth />

                <FileInput source="costCalculationFile" validate={validationRules} maxSize={5000000}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="vehicleRegistrationFile" validate={validationRules} maxSize={5000000}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="serviceConfirmationFile" validate={validationRules} maxSize={5000000}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="tcsInspectionFile" maxSize={5000000}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

            </SimpleForm>
        </Create>
    );
}